@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'CourierPrime';
  src: url('./assets/fonts/CourierPrime-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Degular';
  src: url('./assets/fonts/DegularVariable.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Degular-mono';
  src: url('./assets/fonts/DegularMonoVariable.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'CourierPrime', -apple-system, BlinkMacSystemFont, 'Segoe UI', 
               'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 
               'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffff;
  overflow-x: hidden;
}
* {
  /* border: solid red 1px; */
}

